import Chart from 'chart.js/auto';
import axios from 'axios'
// import { getRelativePosition } from 'chart.js/helpers'
// import { getRelativePosition } from 'chart.js/helpers'
// import { getRelativePosition } from 'chart.js/helpers'
import {valueOrDefault} from 'chart.js/helpers'

    ;

(function (window, document) {
    let charts = document.querySelectorAll(".chartjs-block");
    if (charts && charts.length) {
        charts.forEach(chart => buildChart(chart))
        function buildChart(chart) {
            let dataForGraphic = JSON.parse(chart.dataset.fibos);
            let labels = [];
            let values = [];
            let colors = [];
            for (let i = 0; i < dataForGraphic.length; i++) {
                labels.push(dataForGraphic[i].label);
                values.push(dataForGraphic[i].count);
                colors.push(dataForGraphic[i].color);
            }
            new Chart(chart, {
                type: 'pie',
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: 'label',
                            data: values,
                            backgroundColor: colors
                        }]
                },
                options: {
                    plugins: {
                        legend: {
                            display: true,
                            position: 'right',
                            // labels: {
                            //     color: 'rgb(255, 99, 132)'
                            // }
                        },
                        dataLabels: {
                            formatter: (value) => {
                                return value + '%';
                            }
                        }
                    }
                }
            });
        }

    }

    //Установка времени периодов
    let applyFilterButton = document.querySelector('#applyFilterButton')
    if (applyFilterButton) {
        applyFilterButton.addEventListener('click', function () {
            applyFilter()
        })
    }

    function applyFilter() {
        let counterStartPeriod = document.querySelector('#startPeriod')
        let counterEndPeriod = document.querySelector('#endPeriod')
        if (!counterStartPeriod) {
            alert('Выберите начало периода')
            return;
        }
        if (!counterEndPeriod) {
            alert('Выберите конец периода')
            return;
        }
        if (counterStartPeriod.value.toDate("dd.mm.yyyy hh:ii") >= counterEndPeriod.value.toDate("dd.mm.yyyy hh:ii")) {
            alert('время начала не должно быть больше или равно времени конца периода')
            return;
        }
        let uri = window.location.toString()
        uri = updateQueryStringParameter(uri, 'startPeriod', counterStartPeriod.value)
        uri = updateQueryStringParameter(uri, 'endPeriod', counterEndPeriod.value)
        document.location.replace(uri);
    }

    //window.location.toString()
    function updateQueryStringParameter(uri, key, value) {
        let re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        let separator = uri.indexOf('?') !== -1 ? "&" : "?";
        if (uri.match(re)) {
            return uri.replace(re, '$1' + key + "=" + value + '$2');
        } else {
            return uri + separator + key + "=" + value;
        }
    }

    String.prototype.toDate = function (format) {
        let normalized = this.replace(/[^a-zA-Z0-9]/g, '-');
        let normalizedFormat = format.toLowerCase().replace(/[^a-zA-Z0-9]/g, '-');
        let formatItems = normalizedFormat.split('-');
        let dateItems = normalized.split('-');

        let monthIndex = formatItems.indexOf("mm");
        let dayIndex = formatItems.indexOf("dd");
        let yearIndex = formatItems.indexOf("yyyy");
        let hourIndex = formatItems.indexOf("hh");
        let minutesIndex = formatItems.indexOf("ii");
        let secondsIndex = formatItems.indexOf("ss");

        let today = new Date();

        let year = yearIndex > -1 ? dateItems[yearIndex] : today.getFullYear();
        let month = monthIndex > -1 ? dateItems[monthIndex] - 1 : today.getMonth() - 1;
        let day = dayIndex > -1 ? dateItems[dayIndex] : today.getDate();

        let hour = hourIndex > -1 ? dateItems[hourIndex] : today.getHours();
        let minute = minutesIndex > -1 ? dateItems[minutesIndex] : today.getMinutes();
        let second = secondsIndex > -1 ? dateItems[secondsIndex] : today.getSeconds();
        return new Date(year, month, day, hour, minute, second);
    };

})(window, document);
