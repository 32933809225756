import axios from "axios";
import {notify} from "../components/Notify";

;(function (window, document) {
    let hotInputElements = document.querySelectorAll('.js-index-hot-input');
    hotInputElements.forEach(element => {
        element.addEventListener('click', subscribe);
    });
    function subscribe() {
        let userId = this.getAttribute('data-id')
        let nowValue = this.getAttribute('data-value')
        axios.post(`/admin/clients/${userId}/signal-subscribe`, { signalSubscribe: nowValue === '1' ? 0 : 1 })
            .then(result => {
                let response = result.data;
                if (response.success) {
                    let newValue = response.data.signalSubscribe
                    this.setAttribute('data-value', newValue)
                    this.innerHTML = newValue ? 'Подписан' : 'Не подписан'
                    notify('Операция прошла успешно', 'success', this.innerHTML)
                }
            }, error => {
                notify('Error', 'danger', error.message)
            })
    }
})(window, document);
